import { AssetConf } from "@pro/common/conf";
import { observer } from "mobx-react";
import React, { useEffect } from "react";
import { useApp } from "../../App";
import CabinetTabsView from "./CabinetTabsView";
import CardView from "./CardView";
import Styles from "./SymbolCollection.module.scss";

const BookCollectionView = observer(() => {
	const app = useApp();
	const tabPanelsView = AssetConf.RARITIES.map(rarity => {
		const symbolViews = app.assetConf.heroes
			.filter(hero => hero.rarity === rarity && hero.unpack && !hero.hidden)
			.map(it => {
				let heroModels = app.store.heroes.queryByTemplateId(it.template_id);
				return <CardView data={it}
				                 amount={heroModels.length}
				                 key={`symbol-view-${it.template_id}`}
				/>;
			});

		return (
			<div className={Styles.container}>
				{symbolViews}
			</div>
		);
	});

	useEffect(() => {
		app.worldService.updateAssets().catch();
	}, []);

	return (
		<CabinetTabsView tabPanelViews={tabPanelsView}/>
	);
});

export default BookCollectionView;
